@import "./_var.scss";
@import './mixins';

body,
html {
    background: #000;
    color: #ABABAB;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        background: #CD0402;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #CD0402;
    }
}

.font-195 {
    -webkit-text-stroke: 1.5px #CD0402;
    color: transparent;
    font-family: $furore;
    font-size: 195px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 160px;
    }
}

.font-195-form {
    -webkit-text-stroke: 1.5px #CD0402;
    color: transparent;
    font-family: $furore;
    font-size: 195px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 115px;
    }

    @media(max-width: $md6+px) {
        font-size: 67px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-120 {
    color: $red;
    text-shadow: 0px 0px 100px #D20F0F, 0px 0px 50px #D20F0F;
    font-family: $blackOps;
    font-size: 120px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 20px;
    padding-left: 20px;

    @media(max-width: $md4+px) {
        font-size: 100px;
        letter-spacing: 17px;
        padding-left: 17px;
        text-shadow: 0px 0px 80px #D20F0F, 0px 0px 40px #D20F0F;
    }

    @media(max-width: $md6+px) {
        font-size: 70px;
        letter-spacing: 11px;
        padding-left: 11px;
        text-shadow: 0px 0px 60px #D20F0F, 0px 0px 30px #D20F0F;
    }
}

.font-115 {
    color: #CD0402;
    font-family: $furore;
    font-size: 115px;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 90px;
    }

    @media(max-width: $md4+px) {
        font-size: 70px;
        line-height: 120%;
    }

    @media(max-width: $md5+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }

    span {
        display: inline-flex;
        -webkit-text-stroke: 1.3px $red;
        color: transparent;
        @include animation("noise-2 linear 3s alternate infinite");
    }
}

.font-115-en {
    color: #CD0402;
    font-family: $furore;
    font-size: 115px;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;

    @media(max-width: $md3+px) {
        font-size: 90px;
    }

    @media(max-width: $md4+px) {
        font-size: 70px;
        line-height: 120%;
    }

    @media(max-width: $md5+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }

    span {
        font-size: 85px;
        line-height: 105%;
        display: inline-flex;
        -webkit-text-stroke: 1.3px $red;
        color: transparent;
        @include animation("noise-2 linear 3s alternate infinite");
        
        @media(max-width: $md3+px) {
            font-size: 65px;
        }
        @media(max-width: $md4+px) {
            font-size: 55px;
            line-height: 120%;
        }
        @media(max-width: $md5+px) {
            font-size: 35px;
        }
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-83 {
    color: #ABABAB;
    font-family: $furore;
    font-size: 83px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @include animation("noise-2 linear 3s alternate infinite");

    @media(max-width: $md4+px) {
        font-size: 70px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }
}

.font-83-form {
    color: #ABABAB;
    font-family: $furore;
    font-size: 83px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @include animation("noise-1 linear 3s alternate infinite");

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }
}

.font-80 {
    color: #ABABAB;
    font-family: $furore;
    font-size: 80px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 70px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }

    span {
        color: $red;
    }
}

.font-75 {
    color: #ABABAB;
    font-family: $furore;
    font-size: 75px;
    font-weight: 400;
    line-height: 115%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 35px;
    }

    span {
        display: inline-block;
        color: #CD0402;
        @include animation("noise-1 linear 1s alternate infinite");
    }
}

.font-50 {
    color: #ABABAB;
    font-family: $furore;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 25px;
    }

    span {
        color: $red;
    }
}

.font-35 {
    color: #ABABAB;
    font-family: $futura;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 14px;

    @media(max-width: $md4+px) {
        font-size: 30px;
        letter-spacing: 12px;
    }

    @media(max-width: $md6+px) {
        font-size: 23px;
        letter-spacing: 9px;
    }
}

.font-25 {
    color: #ABABAB;
    font-family: $furore;
    font-size: 25px;
    font-weight: 400;
    line-height: 130%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-18 {
    color: #ABABAB;
    font-family: $futura;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 3px;

    @media(max-width: $md4+px) {
        font-size: 16px;
        letter-spacing: 2.7px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 2.4px;
    }

    span {
        font-family: $blackOps;
        letter-spacing: 3px;
        font-weight: 500;
    }
}

.font-18-title {
    color: #ABABAB;
    font-family: $furore;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        color: $red;
        display: inline-flex;
    }
}

.anim-3 {
    @include animation("noise-1 linear 1s alternate infinite");
}

.mt {
    margin-top: 250px;

    @media (max-width: $md4+px) {
        margin-top: 170px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
