@import '../../../styles/var';
@import '../../../styles/mixins';

.howWorks {
    position: relative;

    .title {
        text-align: center;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 30px 60px;
        margin-top: 75px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: 850px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .card {
            &Title {
                
            }
            &Line {
                margin: 20px 0;
                width: 100%;
                height: 3px;
                background: rgba(171, 171, 171, 0.50);
                @media(max-width: $md4+px) {
                    margin: 15px 0;
                }
            }
            &Text {

            }
            &:nth-child(1) .cardTitle span {
                @include animation("noise-1 linear 3s alternate infinite");
            }
            &:nth-child(2) .cardTitle span {
                @include animation("noise-1 linear 4s alternate infinite");
            }
            &:nth-child(3) .cardTitle span {
                @include animation("noise-1 linear 5.5s alternate infinite");
            }
            &:nth-child(4) .cardTitle span {
                @include animation("noise-1 linear 6s alternate infinite");
            }
            &:nth-child(5) .cardTitle span {
                @include animation("noise-1 linear 4s alternate infinite");
                animation-delay: 1s;
            }
        }
    }
}