@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

@font-face {
    font-family: 'Furore';
    src: url('../assets/fonts/Furore.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../assets/fonts/Futura_400.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
