@import '../../../styles/var';
@import '../../../styles/mixins';

.takeStep {
    position: relative;
    z-index: 3;

    .title, .content {
        position: relative;
        z-index: 2;
    }

    .image {
        position: absolute;
        z-index: 1;
        right: -91px;
        top: 0;
        width: 685px;
        @media(max-width: $md3+px) {
            width: 600px;
            right: -80px;
        }
        @media(max-width: $md4+px) {
            width: 500px;
        }
        @media(max-width: 850px) {
            width: 411px;
            top: 40px;
        }
        @media(max-width: $md5+px) {
            top: -30px;
        }
        @media(max-width: 600px) {
            position: relative;
            display: flex;
            right: unset;
            top: unset;
            margin: 20px auto 0;
            width: 100%;
            max-width: 480px;
        }
        @media(max-width: $md6+px) {
            width: calc(100% + 36px);
            transform: translateX(-18px);
        }

        &_en {
            top: -40px;
            @media(max-width: $md5+px) {
                top: -50px;
            }
            @media(max-width: 600px) {
                top: unset;
            }
        }
    }

    .title {
        
    }

    .content {
        margin-top: 45px;
        @media(max-width: $md4+px) {
            margin-top: 100px;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 40px;
            align-items: center;
        }
        @media(max-width: $md5+px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: initial;
            gap: 30px;
            text-align: right;
        }
        @media(max-width: 600px) {
            margin-top: 20px;
        }
        .text {
            max-width: 525px;
            @media(max-width: $md5+px) {
                max-width: 100%;
            }
        }
        .subtitle {
            text-align: right;
            margin-top: -60px;
            @media(max-width: $md3+px) {
                margin-top: 0;
            }
        }
    }

    .btn {
        display: flex;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;

        @media(max-width: $md4+px) {
            position: relative;
            margin-top: 30px;
        }

        @media(max-width: 800px) {
            justify-content: flex-end;
        }
    }
}