@import '../../../styles/var';

.marquees {
    position: relative;
    padding: 100px 0;

    @media(max-width: $md4+px) {
        padding: 50px 0;
    }

    @media(max-width: $md6+px) {
        padding: 30px 0;
    }

    .marquee {
        position: relative;
        color: #000;
        font-family: $furore;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        @media(max-width: $md4+px) {
            font-size: 20px;
        }

        @media(max-width: $md6+px) {
            font-size: 16px;
        }

        p {
            padding: 20px 0 20px 9px;

            @media(max-width: $md4+px) {
                padding-left: 8px;
            }

            @media(max-width: $md6+px) {
                padding: 14px 0 14px 7px;
            }

            span {
                color: #ABABAB;
            }
        }

        &_01 {
            z-index: 3;
            background: rgba(205, 4, 2, 1);
            transform: rotate(8deg);
            left: -20px;
            width: calc(100% + 40px);
        }

        &_02 {
            z-index: 2;
            background: rgba(205, 4, 2, 0.9);
            transform: rotate(-10deg);
            left: -20px;
            width: calc(100% + 40px);
        }
    }
}