@import '../../../styles/var';

.bannerWrap {
  position: relative;
  z-index: 2;

  .mountain {
    position: absolute;
    z-index: 4;
    bottom: -250px;
    left: 50%;
    transform: translateX(-50%);
    // width: 2834px;
    height: 615px;
    @media (max-width: $md3+px) {
      height: 467px;
      bottom: -170px;
    }
    @media (max-width: $md6+px) {
      height: 319px;
      bottom: -155px;
    }
  }

  .horn {
    position: absolute;
    z-index: 1;
    width: 667px;
    bottom: 95px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: $md3+px) {
      bottom: 0;
    }
    @media (max-width: $md6+px) {
      width: 375px;
      bottom: 18px;
    }
  }

  .circle {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 760px;
    height: 760px;
    border: 1px solid $red;
    border-radius: 100%;

    @media (max-height: 930px) {
      top: 100px;
    }

    @media (max-height: 820px) {
      top: 50px;
    }

    @media (max-width: $md3+px) {
      display: none;
    }
  }

  .bg {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    @media (max-width: $md3+px) {
      left: 50%;
      transform: translateX(-50%);
      width: 1516px;
    }
    @media (max-width: $md6+px) {
      width: 1500px;
      left: 65%;
    }
  }

  .smoke {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 380px;
  }

  .shadow {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.banner {
  position: relative;
  height: 100vh;
  min-height: 770px;
  max-height: 1000px;
  padding-top: 230px;

  @media (max-height: 930px) {
    padding-top: 180px;
  }

  @media (max-height: 880px) {
    padding-top: 160px;
  }

  @media (max-height: 820px) {
    padding-top: 110px;
  }

  @media (max-width: $md3+px) {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
    padding-top: 150px !important;
    padding-bottom: 520px;
  }

  @media (max-width: $md6+px) {
    padding-top: 100px !important;
    padding-bottom: 320px;
  }

  .content {
    position: relative;
    z-index: 10;
    text-align: center;
    .title {
    }
    .subtitle {
      margin: 0 0 80px;
      @media (max-height: 880px) {
        margin-bottom: 40px;
      }
      @media (max-width: $md4+px) {
        margin: 20px 0 50px !important;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;
      @media (max-width: $md4+px) {
        gap: 60px;
      }
      @media (max-width: $md6+px) {
        gap: 40px;
      }
    }
  }

  .stone {
    position: absolute;
    z-index: 6;
    @media (max-width: $md5+px) {
      display: none;
    }
    &_01 {
      width: 145px;
      left: 0;
      bottom: 537px;
      @media (max-height: 820px) {
        bottom: 490px;
      }
      @media (max-width: $md4+px) {
        bottom: unset;
        top: 213px;
        width: 120px;
      }
    }
    &_02 {
      width: 116px;
      left: 140px;
      bottom: 306px;
      @media (max-height: 820px) {
        bottom: 250px;
      }
      @media (max-width: $md3+px) {
        display: none;
      }
    }
    &_03 {
      width: 92px;
      right: 215px;
      bottom: 490px;
      @media (max-height: 820px) {
        bottom: 440px;
      }
      @media (max-width: $md3+px) {
        display: none;
      }
    }
    &_04 {
      width: 73px;
      right: 50px;
      bottom: 650px;
      @media (max-height: 820px) {
        bottom: 600px;
      }
      @media (max-width: $md4+px) {
        bottom: unset;
        width: 60px;
        top: 110px;
        right: 20px;
      }
    }
    &_05 {
      width: 163px;
      right: -20px;
      bottom: 240px;
      @media (max-height: 820px) {
        bottom: 190px;
      }
      @media (max-width: $md4+px) {
        bottom: unset;
        width: 140px;
        top: 445px;
      }
    }
    img {
      width: 100%;
    }
  }
}
