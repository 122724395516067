@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba($color: #000, $alpha: 0);
    backdrop-filter: blur(8px);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    transition: all .1s;
    height: 80px;

    @media(max-width: $md3+px) {}

    @media(max-width: $md6+px) {
        height: 70px;
    }

    &.hideBg {
        background: rgba($color: #ABABAB, $alpha: 0);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    &::before {
        content: '';
        background: #ABABAB;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
        transition: all .3s;
    }

    &Active {
        &::before {
            opacity: 1;
            height: 100vh;
        }
    }

    .container {
        height: 100%;
    }

    .headerRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        height: 100%;

        @media(max-width: $md6+px) {
            gap: 30px;
        }

        &Logo {
            width: 56px;

            @media(max-width: $md6+px) {
                width: 50px;
            }

            a {
                width: 100%;
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            @media(max-width: $md6+px) {
                gap: 30px;
            }

            .socMedia {
                width: 34px;
                transition: all 0.1s;

                &:hover {
                    opacity: 0.7;
                }

                svg {
                    width: 100%;
                }
            }

            .btn {}
        }
    }
}

:global(.Dropdown-control) {
    font-family: $futura !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px !important;
    background-color: transparent !important;
    border: none !important;
    color: #ABABAB !important;
    text-transform: uppercase !important;
    padding-right: 30px !important;
    cursor: pointer !important;

    &:hover {
        box-shadow: none;
    }
}

:global(.Dropdown-menu) {
    font-family: $futura !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

:global(.Dropdown-arrow) {
    border-color: #ABABAB transparent transparent !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

:global(.is-open .Dropdown-arrow) {
    border-color: transparent transparent #ABABAB !important;
}