@import '../../../styles/var';

.dataAnalysis {
    position: relative;

    .head {
        .title {}

        .subtitle {
            margin-top: 10px;
        }
    }

    .text {
        max-width: 525px;
        margin-left: auto;
        margin-top: -20px;

        @media(max-width: $md3+px) {
            margin-top: -5px
        }

        @media(max-width: $md4+px) {
            margin-top: 50px;
            max-width: 452px;
        }

        @media(max-width: $md5+px) {
            max-width: 100%;
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
    }

    .btn {
        display: flex;
        position: relative;
        z-index: 10;
        margin-top: -20px;

        @media(max-width: $md4+px) {
            margin-top: -55px;
        }

        @media(max-width: $md5+px) {
            margin-top: 30px;
        }
    }

    .image {
        display: flex;
        margin: 130px auto 0;
        max-width: 867px;
        width: 100%;
        @media(max-width: $md4+px) {
            margin-top: 75px;
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
        }
    }
}