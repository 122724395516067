@import '../../../styles/var';
@import '../../../styles/mixins';

.advantages {
    position: relative;

    .title {
        position: relative;
        z-index: 5;
        text-align: center;
    }

    .cards {
        position: relative;
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        gap: 120px;

        @media(max-width: $md4+px) {
            align-items: center;
            gap: 40px;
            margin-top: 60px;
        }

        @media(max-width: 700px) {
            gap: 100px;
            margin-top: 140px;
        }

        @media(max-width: $md6+px) {
            gap: 60px;
            margin-top: 60px;
        }

        .card {
            position: relative;
            z-index: 3;
            max-width: 630px;
            width: 100%;

            &:nth-child(1) .cardTitle span {
                @include animation("noise-1 linear 3s alternate infinite");
            }
            &:nth-child(2) .cardTitle span {
                @include animation("noise-1 linear 4s alternate infinite");
            }
            &:nth-child(3) .cardTitle span {
                @include animation("noise-1 linear 5.5s alternate infinite");
            }
            &:nth-child(4) .cardTitle span {
                @include animation("noise-1 linear 6s alternate infinite");
            }

            .wrap {
                display: grid;
                align-items: flex-end;
                justify-content: space-between;

                @media(max-width: 700px) {
                    display: flex;
                }

                .content {
                    position: relative;
                    z-index: 3;
                    padding-bottom: 25px;

                    .cardTitle {
                        @media(max-width: $md6+px) {
                            line-height: 130%;
                        }
                    }

                    .cardText {
                        margin-top: 15px;

                        @media(max-width: 700px) {
                            max-width: 300px;
                        }

                        @media(max-width: $md6+px) {
                            max-width: 220px;
                        }
                    }
                }

                .image {
                    @media(max-width: 700px) {
                        position: absolute;
                        z-index: 2;
                    }

                    &_01 {
                        width: 217px;

                        @media(max-width: $md4+px) {
                            width: 190px;
                        }

                        @media(max-width: 700px) {
                            bottom: 3px;
                            right: 0;
                            width: 160px;
                        }

                        @media(max-width: $md6+px) {
                            width: 90px;
                        }
                    }

                    &_02 {
                        margin-right: -50px;
                        margin-bottom: 20px;
                        width: 372px;

                        @media(max-width: $md4+px) {
                            width: 255px;
                            margin-right: 0;
                        }

                        @media(max-width: 700px) {
                            margin: 0;
                            bottom: 15px;
                            left: 0;
                            width: 240px;
                        }

                        @media(max-width: 600px) {
                            left: -30px;
                        }

                        @media(max-width: $md6+px) {
                            width: 170px;
                            left: -35px;
                            bottom: 10px;
                        }
                    }

                    &_03 {
                        margin-bottom: 20px;
                        width: 169px;

                        @media(max-width: 700px) {
                            right: 0;
                            bottom: 20px;
                            margin: 0;
                            width: 140px;
                        }

                        @media(max-width: $md6+px) {
                            width: 110px;
                            bottom: 10px;
                        }
                    }

                    &_04 {
                        width: 262px;

                        @media(max-width: $md4+px) {
                            width: 229px;
                        }

                        @media(max-width: 700px) {
                            bottom: 3px;
                            left: -15px;
                            width: 180px;
                        }

                        @media(max-width: $md6+px) {
                            width: 100px;
                            left: -8px;
                        }
                    }
                }
            }

            .line {
                position: relative;
                z-index: 5;
                width: 100%;
                height: 3px;
                background: rgba(171, 171, 171, 0.50);
            }

            &:nth-child(1),
            &:nth-child(3) {
                .wrap {
                    grid-template-columns: 390px auto;
                }
            }

            &:nth-child(2),
            &:nth-child(4) {
                margin-left: auto;

                @media(max-width: $md4+px) {
                    margin-left: 0;
                }

                .cardText {
                    margin-left: auto;
                }

                .wrap {
                    grid-template-columns: auto 390px;
                    justify-content: flex-end;
                    text-align: right;
                }
            }
        }

        .item {
            position: absolute;
            z-index: 2;

            @media(max-width: $md3+px) {
                display: none;
            }

            &_01 {
                top: 0;
                right: 250px;
            }
            &_02 {
                top: 15%;
                right: 90px;
            }
            &_03 {
                left: 200px;
                top: 27%;
            }
            &_04 {
                left: 300px;
                top: 30%;
            }
            &_05 {
                left: 100px;
                top: 45%;
            }
            &_06 {
                right: 100px;
                bottom: 32%
            }
            &_07 {
                left: 380px;
                bottom: 17%;
            }
            &_08 {
                left: 140px;
                bottom: 80px;
            }
        }
    }
}